@import "~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references";
.saved-search-criteria{
    background: $ms-color-gray-f-shade-01;
    padding: 10px 5px 20px;
    margin: 10px 0px;
    input{
        background-color: $ms-color-white !important;
    }
    @media screen and (max-width: 1023px) and (min-width:640px){
        input::-webkit-input-placeholder {
            font-size: 10px;
        }
        input:-ms-input-placeholder {
            font-size: 10px;
        }
        input::placeholder {
            font-size: 10px;
        }
    }
    @media screen and  (max-width:639px) and (min-width: 480px){
        input::-webkit-input-placeholder {
            font-size: 8px;
        }
        input:-ms-input-placeholder {
            font-size: 8px;
        }
        input::placeholder {
            font-size: 8px;
        }
    }
    @media screen and  (max-width: 479px){
        input::-webkit-input-placeholder {
            font-size: 6px;
        }
        input:-ms-input-placeholder {
            font-size: 6px;
        }
        input::placeholder {
            font-size: 6px;
        }
    }
    
    .tag-picker-box{
        .ms-Label{
            width: 120px;
        }
    }
    .mange-btn-section{
        margin: 1px 0px 0px;
        color: $ms-color-blue-different-shade-02;       
        padding: 0px 3px;
        float: right;
        >i{
            vertical-align: middle;
        }        
    }
    .padd-right30{
        padding-right: 30px;
    }   
}
.save-search-criteria-panel{
    .search-result{
        .action-buttons{
            display: none !important;
        }
    }    
}  