@import '~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references';
.manual-entry {
	background: $ms-color-gray-f-shade-01;
	padding:15px 10px 25px;
	margin: 1px 0px;
	.manual-entry-list {
		.ms-Label {
			padding: 0px 0px 5px;
		}
		.input-text-option-control
		{
			input:read-only
			{
				background: $ms-color-gray-f-shade-f3;
				pointer-events: all;
				cursor: not-allowed;
				height: 30px;
			}

		}
	}
	.selected-items-list-section {
		outline: 0px Solid $ms-color-black !important;
		:focus
		{
			outline: 0px Solid  $ms-color-black !important;
		}
		.remove-all-button:focus{
			outline: 1px Solid !important;
		}
		margin-left: -2px;
		:empty {
			display: none;
		}
		.statusmessage
		{
		position:absolute;
		width:1px;
		height:1px;
		margin-top:-1px;
		margin-right:-1px;
		margin-bottom:-1px;
		margin-left:-1px;
		padding-top:0px;
		padding-right:0px;
		padding-bottom:0px;
		padding-left:0px;
		border:0px;
		overflow:hidden;
		}
	}
	.add-button {
		padding: 0px;
		margin-top: 23.5px;
		cursor: pointer;
		.is-disabled
		{
			border: 1px solid $ms-color-black;
			pointer-events: all;
			cursor: not-allowed;
		}
		.button-link {
			margin: 0px;
			padding: 0px;
			height: 32px;
			border: 1px solid $ms-color-blue-different-shade-01;
			background: $ms-color-blue-different-shade-01;
			color: $ms-color-white;
			font-weight: 500;
			font-size: 16px;
			text-align: center;
			border-left: 0px solid $ms-color-blue-different-shade-01;
			&.is-disabled {
				border: 1px solid $ms-color-black-different-shade-A6;
				border-left: 0px solid $ms-color-black-different-shade-A6;
				background: $ms-color-white;
				color: $ms-color-black-different-shade-A6;
				pointer-events: all;
				cursor: not-allowed;
			}
		}
		.button-link:focus,
		.button-link.skip:hover {
			border: 1px solid $ms-color-white;
			outline: 1px solid $ms-color-black;
		}
		//small screen 320px - 479px
		@media only screen and (max-width: 479px) and (min-width: 320px) {
			padding: 0px;
			margin-top: 1px;
			border-left: 1px solid $ms-color-black-different-shade-A6;
		}
	}
}
.coa-number-error {
	float: left;
	padding: 0px;
	text-align: justify;
	color: $ms-color-red-different-shade-01;
	font-size: 12px;  
	padding-top: 6px;
	@media only screen and (max-width:639px) and (min-width: 480px)  {
		white-space:pre-wrap;
	}
	//small screen 320px - 479px
	@media only screen and (max-width: 479px) and (min-width: 320px)  {
		white-space:pre-wrap;
	}       
}

