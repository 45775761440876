.loading {
    position:fixed; 
    display: grid;
    top: 0;   
    width:100%;
    height:100%;
    background-color: rgba(0, 0,0,0.5);
    background-repeat:no-repeat;
    background-position:center;
    z-index : 101;
    opacity: 1; 
    vertical-align: middle;
    .ms-Spinner-circle 
    {
        opacity: 1;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .ms-Spinner
        {
            padding-top: 15% !important;
        }
    }
}

.panel-loading {
    position:absolute;
    top:40%;
    right:0px;
    width:100%;
    background-repeat:no-repeat;
    background-position:center;
    z-index : 101;
    opacity: 1;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}