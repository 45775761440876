@import '~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references';
.notifications-panel
{

    //panel Header Section
    margin-top: 39px;  
    padding: 0px 10px 0px 15px;  
    .ms-Panel-main
    {
      max-width: 400px;
      background:$ms-color-black-different-shade-04F;  
      font-family: "Segoe UI";
      //panel title styling
      .ms-Panel-commands
      {
      padding:5px 20px 10px 20px; 
          .panel-title-section
          {
                font-size: 18px;
                color: $ms-color-white;
                font-weight: 400;
                border-bottom: 1px solid $ms-color-black-different-shade-07; 
                padding-left: 8px;
                padding-bottom: 5px;
                .panel-title
                {
                  text-align: justify;
                  padding: 5px 0px 5px 0px;
                }
          }
      }
    }
    
    //Panel Content Section
  
    .ms-Panel-contentInner
    {
        .ms-Panel-scrollableContent
        {
            .ms-Panel-content
            {
             padding:0px;             
                //panel contents links 
                .notification-template 
                {
                padding:1px 20px 10px 20px; 
                //panel header 
                .header-section-text
                {
                    font-size: 18px;
                    color: $ms-color-white;
                    font-weight: 500; 
                    padding-bottom: 5px;
                    border-bottom: 1px solid $ms-color-black-different-shade-07;
                
                    .header-text
                    {
                    text-align: justify;
                    padding-bottom: 5px;
                    }
                
                    .right-side-link, .ms-Link  
                    {
                    color: $ms-color-white; 
                    padding: 2px 0px 0px 0px; 
                    font-size: 15px;
                    text-align: center;
                    font-weight: 500;
                    cursor:pointer;
                    a {
                    border:0px;
                    cursor:pointer;
                    color: $ms-color-white;  
                    text-decoration: none;              
                    }
                    >a:hover,
                    >a:focus
                    {
                        outline: 1px solid $ms-color-white;                  
                    }  
                    }
                    .right-side-link:hover,
                    .right-side-link:focus
                    {
                    outline: 1px solid $ms-color-white;  
                    }
                }  
                
                // Notification panel
                .panel-section
                {
                    font-family: "Segoe UI";        
                    color: $ms-color-white;
                    font-weight: 100;
                    padding:10px 0px 10px 0px;
                    border-bottom: 1px solid $ms-color-black-different-shade-07;

                    .panel-section-title
                    {
                        padding-bottom: 10px;
                        font-weight: 500;            	
                        font-size: 14px;
                    }

                    .panel-section-text
                    {
                        font-size: 14px;
                        color: $ms-color-white;
                        font-weight: 100;                           
                        .notification-text
                        {
                            text-align: inherit;                 
                        }
                        .notification-date
                        {
                            padding-bottom: 10px;
                            text-align: left;                 
                        }
                        .contents-section-text
                        {
                            text-align: inherit;
                            margin-bottom: 10px;       
                        
                            .contents-section-text-link
                            {
                                cursor:pointer;
                                color: $ms-color-white; 
                                text-decoration: none;
                            }  
                            .contents-section-text-link:hover,
                            .contents-section-text-link:focus
                            {
                                outline: 1px solid $ms-color-white;                
                            } 
                        }            
                    }
                    
                }
                    .panel-section:last-of-type
                    {
                    border: 0px;
                    }
                }
            }
        }
        
    }
    //Panel Footer Section links
    .ms-Panel-footer
    {
        .ms-Panel-footerInner 
        {
            padding:0px;
            .panel-footer
            {
            position: absolute;
            padding: 10px 10px 10px 0px;
            bottom: 0;
            width: 100%;
            background-color: $ms-color-black-different-shade-04F;
                .container
                {
                    width: auto;   
                    .ms-CommandBar
                    {
                        padding: 0px;                                 
                        .panel-footer-navigation-bar
                        { 
                            color: $ms-color-white;                
                        }
                    }  
                }        
            }
        }   
    } 
}