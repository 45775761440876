@import "../../resources/styles/variables/references";
.search-result{    
    margin-top: 5px;
    .search-result-status{
        font-size: 20px;
        margin: 10px 0px 0px 0px;        
        padding-bottom: 4px;        
    }
    .search-result-data{
        .ms-Grid-row
        {
          margin: 0px;
        }      
        clear: both;
        .pager-data{
            margin-top: 10px;           
            //For small screen
            @media (min-width: 320px) and (max-width:479px){
                text-align: center;
                .pager{
                    display: inline-block;
                    .pagination-label,.pageno-input{
                        padding-left: 0px !important;
                    }
                }
            }
            //For Medium sized Screen
            @media (min-width: 480px) and (max-width:639px){
                text-align: center;
                .pager{
                    display: inline-block;
                    .pagination-label, .pageno-input{
                        padding-left: 0px;
                    }
                }
            }
            //For Large sized Screen
            @media (min-width: 640px) and (max-width:768px){
                .pager{
                    display: inline-block;
                    .pagination-label, .pageno-input{
                        padding-left: 0px;
                    }
                }
            }

        }
    }
    .filter-section
    {
        margin: 0px 0px 10px 0px;
        padding: 0px 15px 10px 0px;
        .search-filters{
            margin-top: 10px;
            border-bottom: 1px solid $ms-color-black-different-shade-060;        
            .search-filter{
                
                .accordion{
                    border: none;
                }
                .accordion-header:focus{
                    outline: none;
                    border:1px solid $ms-color-black-different-shade-06;
                }
            }
            .search-filter ~.search-filter{
                border-top:1px solid $ms-color-neutralTertiaryAlt;
            } 
        }
         //For small screen
         @media (min-width: 320px) and (max-width:479px){
            padding: 0px 0px 10px 0px;
        }
        //For Medium sized Screen
        @media (min-width: 480px) and (max-width:639px){
            padding: 0px 0px 10px 0px;
        }

    }

    .search-filter-icon{
        width:25px;
        font-size:16px;
        cursor: pointer;
    }
    .search-input-paramters{
        margin: 5px 0px;
        .label {
            font-size: 14px;
            color: $ms-color-black-different-shade-06;
        }
        .value {
            font-size: 14px;
            color: $ms-color-black;
        }  
    }
    .no-search-result-status{
        font-size: 22px;
        margin: 0px 0px 10px 0px;        
        padding-bottom: 6px;
    }
    

    .search-filters-header{
        border-bottom: 1px solid $ms-color-black-different-shade-060;
        font-size: 20px;
        padding-bottom: 5px;
        display: flex;
        i{
            font-size:16px;   
            display: inline;
            padding-right:10px;            
        }

        .search-filters-header-actions{
            margin-left: auto;
            font-size: 16px;
            a{            
                text-decoration: none;
            }
        }  
        //For small screen
         @media (min-width: 320px) and (max-width:479px){
            .filter-option {
                width: 25px;
                overflow: hidden;
                white-space: pre;
                margin-right: 14px;
                font-size: 20px;
                margin-top: 0px;
            }
        }
        //For Medium sized Screen
        @media (min-width: 480px) and (max-width:639px){
            .filter-option {
                width: 25px;
                overflow: hidden;
                white-space: pre;
                margin-right: 14px;
                font-size: 20px;
                margin-top: 0px;
            }
        }      
    }
    .action-buttons{
        display: flex;  
        .select-all-item{
            padding: 12px 0px 5px 0px;
            
        }
        .filter-options-control
        {
            padding: 0px 0px 5px;
            &.remove-padd
            {
                padding: 0px 5px !important;
            }
            .control-contents{
                display:flex; 
                float: right;
                .column-picker-selection{                    
                    padding: 0px 0px 0px 10px;
                    margin: 5px 0px 0px;
                    .ms-Icon 
                    {
                        vertical-align: middle;
                        padding: 0px 5px;
                    }
                }
                .exportOption
                {
                    text-decoration: none;
                    border:0px solid $ms-color-gray-c-shade-01;                            
                    outline: 0px solid $ms-color-white;
                    padding: 0px 0px 1px;
                    margin: 0px 0px 0px 5px;

                    .ms-Button
                    {
                        background: $ms-color-white;
                        color:$ms-color-blue-different-shade-01;
                        border:1px solid $ms-color-gray-c-shade-01;
                        .ms-Icon--Download
                        {
                            margin-right: 5px;

                        }
                    }
                    .ms-Icon[class*="menuIcon"]{
                        color : #8c8c8c;
                    }
                }
                .sort-by-filter{
                    padding: 0px 0px 0px 10px;
                    margin: 0px;
                    .ms-Label{
                         margin: -30px 7px 0px 0px;
                         text-align: left;
                    }
                 } 

            }
        }
         //For small screen
        @media (min-width: 320px) and (max-width:479px){
            display: block;           
            .select-all-item{
                margin-top: 10px;
            }    
            .control-contents{
                display: block;
                margin: 10px 0px;
                .column-picker-selection{  
                    margin: 10px 0px;
                }
                .exportOption
                {
                    margin: 10px 1px;
                } 
                .sort-by-filter{                   
                    margin: 10px 0px;                  
                 } 
            } 
            
        }
        //For Medium sized Screen
        @media (min-width: 480px) and (max-width:639px){
            display: block;
            .control-contents{
                display: block;
                margin: 10px 0px;
                .column-picker-selection{  
                    margin: 10px 0px;
                }
                .exportOption
                {
                    margin: 10px 1px;
                } 
                .sort-by-filter{                   
                    margin: 10px 0px;                  
                 } 
            } 
        }
      

        .search-filters-show-header{
            font-size: 20px;
            margin-top: 10px;
            padding-right:10px;    
            i{            
                font-size:16px;   
                display: inline;
                padding-right:10px;      
            } 
            
            //IE related CSS
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                .search-filters-header-text {
                    display: inline-flex;
                }
            }

            //For Edge browser
            @supports (-ms-ime-align: auto) {
                .search-filters-header-text {
                    display: inline-flex;
                }  
            }

        }

        .action-buttons-right{
            margin-left:auto;
            display: flex;
        }
    }
    .search-filter{
        margin: 5px 0px;
        

        .search-filter-name{
            font-size: 18px;
            margin: 0px 0px 5px 0px;  
            overflow: hidden; 
            text-overflow: ellipsis;
            //For small screen
            @media (min-width: 320px) and (max-width:479px)
            {
                max-width: 100px;
            }
            //For Medium sized Screen
            @media (min-width: 480px) and (max-width:639px)
            {
                max-width: 100px;
            }
            //For Large sized Screen
            @media (min-width: 640px) and (max-width:990px)
            {
                max-width: 100px;
            }
            
        }
        .search-date-filter-items{
            margin: 5px 0px;
            display: flex;
            .search-date-filter-item
            {
                margin: 0px 5px 0px 0px;
                :nth-child(2n)
                {
                    margin-right: 0px;
                }

            }
        }
        .search-filter-items{
            &.date-range
            {
                display: flex;            
            }
            margin: 5px;
            .search-filter-item{
                display: flex;
                margin: 3px 0px 3px 0px;
                &.date-range
                {                    
                    margin :3px 3px 3px 3px;
                }
                .search-filter-item-checkbox{
                   width: 100%;
                   
                }
                .search-filter-item-count{
                    margin-left: auto;
                    .mrgRgt8
                    {
                        margin-right: 8px;
                    }
                }
            }

        }
    }
    .filter-badge-section-area 
    {
        outline: 0px solid;
    }
    .selected-item-badge-section-area
    {
        outline: 0px solid;
    }
    .filter-badge{ 
        padding-right: 3px; 
        .remove-all-button{
            margin: 0px;
            text-decoration: none;
            font-size: 14px;
            padding-left:5px; 
        }   
        .accordion 
        {
            cursor: pointer;
          border-top:0px solid $ms-color-gray-c-shade-01;  
          margin: 10px 0px;   
          &.collapsed
          {
            .accordion-header
            {        
                border: 1px solid $ms-color-gray-c-shade-01;              
            }
          }
          &.expanded
          {
            .accordion-header
            {        
              border: 1px solid $ms-color-gray-c-shade-01;
              border-bottom: 0px solid $ms-color-gray-c-shade-01; 
            }
          }
          .accordion-header
          {        
            background: $ms-color-gray-f-shade-01;
            height: 40px;         
            .store-count-section-heading
            {
              color:  $ms-color-black-different-shade-06;
              font-size: 15px;
              padding: 0px 0px 0px 10px;
              font-weight:400;
              .attach-count ,.deatch-count
              {
                font-weight: 600;
                
              }
            }
            .accordion-header-text
            {
              width: 100%;
              border: 0px solid $ms-color-gray-c-shade-01; 
              padding: 8px 0px; 
              font-size: 15px;
            }
            .accordion-header-icon
            {
            vertical-align: middle;     
            }
          }
        
          .accordion-body
          {
            padding: 0px;
            border-left: 1px solid $ms-color-gray-c-shade-01;
            border-right: 1px solid $ms-color-gray-c-shade-01;
            border-bottom: 1px solid $ms-color-gray-c-shade-01;      
            .selected-fillter-badge-items
            {
                .filter-badge-item-section
                {
                border: 1px solid $ms-color-neutralTertiaryAlt;             
                border-radius: 8px;                 
                margin: 5px; 
                display: inline-block; 
                .filter-badge-item-text {                
                    padding: 6px 5px;
                    vertical-align: sub;
                    max-width: 200px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: inline-block;
                    font-size: 13px;
                }
                .filter-badge-item-btn {              
                    padding: 0px;
                    margin: 0px;
                } 
                } 
            }
          }
        }

    }   
    .search-filter-box{
        .search-box-control
        {
            margin: 0px;            
        }
    }
    .selected-items {
        padding-right: 3px; 
        .remove-all-button{
            margin: 0px;
            text-decoration: none;
            font-size: 14px;
            padding-left:5px; 
        }   
        .accordion 
        {
            cursor: pointer;
            border-top:0px solid $ms-color-gray-c-shade-01;  
            margin: 10px 0px;   
            &.collapsed
            {
                .accordion-header
                {        
                    border: 1px solid $ms-color-gray-c-shade-01;
                }
            }
            &.expanded
            {
                .accordion-header
                {        
                    border: 1px solid $ms-color-gray-c-shade-01;
                    border-bottom: 0px solid $ms-color-gray-c-shade-01;
                }
            }
            .accordion-header
            {        
                background: $ms-color-gray-f-shade-01;
                height: 40px;         
                .store-count-section-heading
                {
                color:  $ms-color-black-different-shade-06;
                font-size: 15px;
                padding: 0px 0px 0px 10px;
                font-weight:400;
                .attach-count ,.deatch-count
                {
                    font-weight: 600;
                    
                }
                }
                .accordion-header-text
                {
                width: 100%;
                border: 0px solid $ms-color-gray-c-shade-01; 
                padding: 8px 0px; 
                font-size: 15px;
                }
                .accordion-header-icon
                {
                vertical-align: middle;     
                }
            }
            
            .accordion-body
            {
                    padding: 0px;
                    border-left: 1px solid $ms-color-gray-c-shade-01;
                    border-right: 1px solid $ms-color-gray-c-shade-01;
                    border-bottom: 1px solid $ms-color-gray-c-shade-01;      
                    .selected-items-section
                    {
                        .selected-item-badge
                        {
                            border: 1px solid $ms-color-neutralTertiaryAlt;             
                            border-radius: 8px;                 
                            margin: 5px; 
                            display: inline-block; 
                            .selected-item-badge-text {                
                                padding: 6px 5px;
                                vertical-align: sub;
                                max-width: 200px;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                white-space: nowrap;
                                display: inline-block;
                                font-size: 13px;
                            }
                            .selected-item-badge-btn {              
                                padding: 0px;
                                margin: 0px;
                            } 
                        } 
                    }
                }
        }
    }
}
//For small screen
@media (min-width: 320px) and (max-width:479px){
    .search-input-paramters{
        padding-bottom: 8px;
    }
    .filter-item-view{
        display: inline-flex;
        max-width: 250px;
        margin-bottom: 5px;
    }    
}
//For Medium sized Screen
@media (min-width: 480px) and (max-width:639px){
    .search-input-paramters{
        padding-bottom: 8px;
    }
    .filter-item-view{
        display: inline-flex;
        max-width: 250px;
        margin-bottom: 5px;
    }
}
//For Large sized Screen
@media (min-width: 640px) and (max-width:1023px){
    .search-input-paramters{
        padding-bottom: 8px;
    }
    .filter-item-view{
        display: inline-flex;
        max-width: 250px;
        margin-bottom: 5px;
    }
}
//For Extra Large screen
@media (min-width: 1024px) and (max-width:1365px){
    .search-input-paramters{
        padding-bottom: 8px;
    }
    .filter-item-view{
        display: inline-flex;
        max-width: 250px;
        margin-bottom: 5px;
    }
}