@import '~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references';
.local-header-wrapper{
    background: $ms-color-white;
    border: 1px;
    box-shadow: 0px 2px 5px 0px $ms-color-gray-c-shade-01, 0px 1px 1px 0px $ms-color-gray-c-shade-01;
}
.header-callout
{
     border:1px solid $ms-color-black-different-shade-04;
     .ms-Callout-beak
     {
        background: $ms-color-black-different-shade-04;
     }
    .ms-Callout-main 
    {
     
       background: $ms-color-black-different-shade-04;
       min-width: 100px;
       min-height: 35px;
        .ms-CalloutExample-header
        {
           
            .ms-CalloutExample-title
            {
                font-weight: 500;
                font-size: 16px;
                text-align: left;
                color: $ms-color-white;
                margin: 5px;
                .ms-Icon {
                    padding: 0px 5px;
                    font-size: 22px;
                    vertical-align: middle;
                }
            }

        }
        
    }

}