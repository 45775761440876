@import '~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references';

.ms-Dropdown-items
{
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 8px 0px;
    .ms-Dropdown-item
    {
        background: none;
    }
} 

@media only screen and (max-width: 479px) and (min-width: 320px) and (orientation:portrait) {
    .ms-Dropdown-items {
        max-height: none;
        overflow-y: auto;
    }   
    .ms-Dropdown-items::-webkit-scrollbar-track{
        background: transparent;
    }
}

/* width */
.ms-Dropdown-items::-webkit-scrollbar{
    width: 4px;
}

/* Track */
.ms-Dropdown-items::-webkit-scrollbar-track {
    background: $ms-color-gray-c-shade-01;
}

/* Handle */
.ms-Dropdown-items::-webkit-scrollbar-thumb 
{
    background: $ms-color-black-different-shade-08; 
}

/* Handle on hover */
.ms-Dropdown-items::-webkit-scrollbar-thumb:hover{
    background: $ms-color-black-different-shade-08; 
}

.dropdown-selected-items-list-section
{
    .statusmessage
    {
    position:absolute;
    width:1px;
    height:1px;
    margin-top:-1px;
    margin-right:-1px;
    margin-bottom:-1px;
    margin-left:-1px;
    padding-top:0px;
    padding-right:0px;
    padding-bottom:0px;
    padding-left:0px;
    border:0px;
    overflow:hidden;
    }
}