.email-picker{
    .email-picker-label{
        .mandatory-field-symbol{
            color: rgb(168, 0, 0);
            padding-right: 12px;            
        }
    }   

    .email-picker-control {
        .ms-BasePicker-text{
            border-width: 1px;
            border-style: solid;
            border-image: initial;
            background: rgb(255, 255, 255);
            border-color: rgb(168, 0, 0);
        }
    }
   
    .email-picker-control-error{
        color: rgb(168, 0, 0);
    }
}