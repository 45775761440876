@import '../../resources/styles/variables/references';
.success-confirmation
{
    margin-top: 100px;
    display: flex;
    justify-content: center;
    .success-card
    {
        cursor: default;
        background: $ms-color-gray-f-shade-00;
        box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.2);
        margin:20px 10px 30px 10px;
        max-width: 600px; 
        border-radius:5px; 
        padding: 0px 0px 25px 0px;
        .success-icon
        {         
            text-align: center;
            .ms-Icon
            {
            background: $ms-color-blue-different-shade-02; 
            padding: 10px;
            font-size: 40px;
            color: $ms-color-white;
            border-radius: 50px;
            border: none;
            margin-top: -30px;
            } 
        }
        .success-card-message
        {
            text-align: center;
            margin: 20px;
            .success-message-header
            {
            font-weight: 450;
            font-size: 35px; 
            }
           
        }     
        .success-card-body
        {            
            text-align: center;     
            padding: 0px 10px 0px 10px;      
            .success-message
            {
            padding: 10px 0px 0px 0px; 
            font-weight: 450;
            font-size: 15px; 
            }            
        }        
        .success-card-links
        {
            text-align: center;
            margin: 20px;
            .success-card-link
            {
            color:$ms-color-blue-different-shade-02;
            vertical-align: middle;
            font-size: 17px;
            text-align: center;
            font-weight: 450;
            margin: 10px;
                .ms-Icon
                {
                vertical-align: middle;
                padding-right: 3px;
                }
                .success-card-text {
                color: $ms-color-blue-different-shade-02;
                vertical-align: middle;
                
                }
            }
        }
    }
}