@import '~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references';
.navbar-brand 
{
    color: $ms-color-black-different-shade-02F;

    &.microsoft {
        margin: 0px 0px 0px -5px;
        border: 0px;
        float: left;

        img {
            height: 22px;
            width: auto;
            float: left;
            padding-top: 9px;
            padding-right: 9px;
        }
    }

    &.oem-doc {
        color: $ms-color-white;
        border: 0px;
        position: absolute;
	    padding-left: 10px;
        padding-top: 10px;
        
        &:link, &:active, &:hover, &:visited {            
            text-decoration: none;
        }        
    }

    &.seperator {
        color: $ms-color-white;	
        padding-right: 8px;	
        position: absolute;
        margin-top: 8px;    
    }
}