@import '../../resources/styles/variables/references';
$ms-color-disable-text: $ms-color-gray-c-shade-08;


 .option-button {
    
    .option-Label {
        text-align: left;
    }
   
    .button {
        font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI" "Helvetica Neue";
        padding:8px 16px;
        font-size: 14px;
        font-weight: 600;
        background-color:$ms-color-white;
        border: 1px solid $ms-color-card-border;   
        display: inline-block;
        position: relative;
        text-align: center;
        min-width: 120px;
        height: 14px;
    }
        
    .button-error{
        border: 1px solid rgb(168, 0, 0);
    }
    .option-error {        
        clear: both;
        display: block;
        .option-error-msg{
            color: rgb(168, 0, 0);
        }
    }
    .option-elements{
        
    }
}

.custom-radio.switch-radio {
    label {
        border: 1px solid $ms-color-card-border;   
        float: left;
        position: relative;
        cursor: pointer;
        margin-bottom: 0;
        background:$ms-color-white;

        

        &:before, &:after {
            display: none;
            height: initial;
            border-radius: 0;
        }
    }

    input:checked ~ label {
        color: $ms-color-active-button;

        &:before {
            display: block;
            content: "";
            position: absolute;
            width: 4px;
            top: -1px;
            bottom: -1px;
            left: -1px;
            background: $ms-color-active-button;
            height: initial;
            border-radius: initial;
        }
    }

    input:disabled + label {
        color: $ms-color-disable-text;
        cursor: not-allowed;

        &:before {
            background: $ms-color-disable-text;
        }
    }

    .custom-control-input:focus + label {
        outline: 1px solid $ms-color-black !important;
        border: 2px solid $ms-color-white !important;
    }
}
.custom-control-inline{
    display: inline-flex;
    margin-right: 1rem;
}
.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

input[type="radio"][readonly], input[type="radio"][readonly] ~ label {
    pointer-events: none;
}

.option-button-with-fill-background
{
    .option-button
    {
        .switch-radio
        {
            padding: 0px;
            margin: 5px 10px 5px 0px;
            .custom-control-input
            {
                padding: 0px;
                margin: 0px;                  
                + label
                {
                        outline: 1px solid $ms-color-white;
                        border: 1px solid $ms-color-gray-d-shade-07;
                        padding: 5px 10px 10px;
                        margin: 1px;
                        @media screen and (-ms-high-contrast: black-on-white) {
                            outline: 0px solid  !important;
                            border: 1px solid  !important;
            
                        }
                        @media screen and (-ms-high-contrast: white-on-black) {
                            outline: 0px solid  !important;
                            border: 1px solid  !important;
                        }
                }
                &:focus 
                { 
                        padding: 10px;
                        margin: 5px;
                        outline: 0px solid ;
                        border: 0px solid ;
                        + label
                        {
                            outline: 1px solid $ms-color-black !important;
                            border: 1px solid $ms-color-black !important;
                            padding: 5px 10px 10px;
                            margin: 1px;  
                            @media screen and (-ms-high-contrast: black-on-white) {
                                outline: 1.5px solid  !important;
                                border: 1.5px solid  !important;
                
                            }
                            @media screen and (-ms-high-contrast: white-on-black) {
                                outline: 1.5px solid  !important;
                                border: 1.5px solid  !important;
                            }                           
                            
                        }
                }
                &:checked
                {
                        ~label
                        {
                            background: $ms-color-blue-different-shade-01;                              
                            color:$ms-color-white;   
                                                                                
                            &:before
                            {
                            content: none;
                            }
                            @media screen and (-ms-high-contrast: black-on-white) {
                                border-left: 5px solid  !important;                                  
                
                            }
                            @media screen and (-ms-high-contrast: white-on-black) {
                                border-left: 5px solid  !important;
                            }
                        
                        }
                        &:focus
                        {
                            ~label
                            {                                  
                                outline: 1px solid $ms-color-black !important;
                                border: 1px solid $ms-color-white !important;                              
                                @media screen and (-ms-high-contrast: black-on-white) {
                                    border: 2px solid  !important;                                                                   
                    
                                }
                                @media screen and (-ms-high-contrast: white-on-black) {
                                    border: 2px solid  !important;
                                    
                                }
                            }
                        }
                }
            }
        } 
    }
}
.option-section .option-button .custom-radio.switch-radio input:checked ~ label{
    @media screen and (-ms-high-contrast:black-on-white){
        font-weight: 900 !important;
        border: 3px solid !important;
        zoom : 1.1 !important;
    }
    @media screen and (-ms-high-contrast:white-on-black){
        font-weight: 900 !important;
        border: 3px solid !important;
        zoom : 1.1 !important;
    }
}