@import "~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references";
.success-confirmation-msg
{
    margin-top: 100px;
    display: flex;
    justify-content: center;
    .success-card
    {
        cursor: default;
        background: $ms-color-gray-f-shade-00;
        box-shadow: 0 2px 4px 0px rgba(0, 0, 0, 0.2);
        margin:20px 10px 30px 10px;
        max-width:550px; 
        border-radius:5px; 
        padding: 0px 0px 25px 0px;
        .success-icon
        {         
            text-align: center;
            .ms-Icon
            {
            background: $ms-color-blue-different-shade-02; 
            padding: 10px;
            font-size: 40px;
            color: $ms-color-white;
            border-radius: 50px;
            border: none;
            margin-top: -30px;
            } 
        }
        .success-card-message
        {
            text-align: center;
            margin: 25px;
            .success-message-header
            {
            font-weight: 450;
            font-size: 35px; 
            }
            .success-message
            {
            padding:20px 0px 0px 0px; 
            font-weight: 450;
            font-size: 15px; 
            } 
            .success-notification-message
            {
            padding: 20px 30px 0px;
            font-weight: 400;
            font-size: 13px;
            } 
        }        
        .success-card-links
        {
            text-align: center;
            padding: 0px 25px;
            margin: 20px  25px ;
            .success-card-link
            {
            color:$ms-color-blue-different-shade-02;
            vertical-align: middle;
            font-size: 17px;
            font-weight: 450;
            text-align: left;
            padding: 5px 10px;
            border: 0px solid;
                .ms-Icon
                {
                vertical-align: middle;
                padding-right: 6px;
                }
                .success-card-text {
                color: $ms-color-blue-different-shade-02;
                vertical-align: middle;
                
                }
            }
        }
    }
}