@import "../../resources/styles/variables/references";
.grid-view-details-list {
	.selection-toggle {
		font-size: 18px;
		padding: 1px;
		&.item-disabled {
			pointer-events: all;
			cursor: not-allowed;
		}
	}
	.column-picker-selection {
		float: right;
		padding: 10px 0px;
		.ms-Icon {
			vertical-align: middle;
			padding: 0px 5px;
		}
	}
	.details-table-section {
		border: 1px solid $ms-color-gray-d-shade-07;
		border-bottom: 0px solid $ms-color-gray-d-shade-07;
		.ms-FocusZone.ms-DetailsRow {
			min-width: 100% !important;
		}
		.ms-DetailsHeader {
			padding: 0px;
			.ms-DetailsHeader-cell {
				color: $ms-color-black;
				background-color: $ms-color-gray-f-shade-01;
				white-space: break-spaces;
				&:hover {
					background-color: $ms-color-gray-f-shade-01;
				}
				.ms-DetailsHeader-cellTitle {
					font-size: 14px;
					font-weight: 500;
				}
			}
		}
		.ms-DetailsRow {
			font-size: 12px;
			background-color: $ms-color-white;
			border-bottom: 1px solid $ms-color-gray-d-shade-07;
			.ms-DetailsRow-cell {
				font-size: 14px;
				.ms-TextField-wrapper {
					.ms-TextField-fieldGroup {
						height: 30px;
					}
				}
				.quantity-button {
					padding: 6px;
					max-width: 150px;
					&.error {
						border: 1px solid $ms-color-red-different-shade-01;
					}
				}
				.error-message {
					color: $ms-color-red-different-shade-01;
					padding-top: 6px;
					font-size: 12px;
					white-space: normal;
					min-width: 110px;
				}
				.line-item-error-message {
					color: $ms-color-red-different-shade-01;
					font-size: 12px;
					white-space: normal;
				}
			}
			.error-icon {
				color: $ms-color-red-different-shade-01;
			}
		}
	}
	.ms-DetailsList {
		> div:first-child {
			overflow: auto;
		}
		/* width */
		> div:first-child::-webkit-scrollbar {
			width: 4px;
			height: 4px;
		}
		/* Track */
		> div:first-child::-webkit-scrollbar-track {
			background: $ms-color-gray-c-shade-01;
		}
		/* Handle */
		> div:first-child::-webkit-scrollbar-thumb {
			background: $ms-color-black-different-shade-08;
		}

		/* Handle on hover */
		> div:first-child::-webkit-scrollbar-thumb:hover {
			background: $ms-color-black-different-shade-08;
		}
	}
	.pager {
		margin: 10px 0px;
	}
}

.column-list-popout {
	border: 1px solid $ms-color-white;
	padding: 0px;
	max-width: 450px;
	.ms-Callout-beak {
		background: $ms-color-white;
	}
	.ms-Callout-beakCurtain {
		background: $ms-color-white;
	}
	.ms-Callout-main {
		max-height: 300px !important;
		background: $ms-color-white;
		.ms-Tooltip-content {
			background: $ms-color-white;
			padding: 10px;
			.ms-Tooltip-subtext {
				color: $ms-color-black;
			}
		}
		//small screen 320px - 479px
		@media only screen and (max-width: 479px) and (min-width: 320px) {
			max-height: 150px !important;
		}
		//medium screen 480px - 639px
		@media only screen and (max-width: 639px) and (min-width: 480px) {
			max-height: 200px !important;
		}
	}
	.ms-Callout-main {
		> div:first-child {
			overflow: auto;
		}
		/* width */
		> div:first-child::-webkit-scrollbar {
			width: 4px;
			height: 4px;
		}
		/* Track */
		> div:first-child::-webkit-scrollbar-track {
			background: $ms-color-gray-c-shade-01;
		}
		/* Handle */
		> div:first-child::-webkit-scrollbar-thumb {
			background: $ms-color-black-different-shade-08;
		}

		/* Handle on hover */
		> div:first-child::-webkit-scrollbar-thumb:hover {
			background: $ms-color-black-different-shade-08;
		}
	}
}
