@import "~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references";
.page-header-text-section
{
    .section-Information-Icon
    {
        .ms-Icon
        {
            font-size: 20px;
            margin: 10px 0px 0px 10px;
            vertical-align: top;
            color: $ms-color-blue-different-shade-01;
            cursor: pointer;
            height: 8px;
            width: 8px;
        }
    }

}
.tooltip-header-title
{
    padding: 10px;
}
.page-header-deflex{
    display: flex;
}