@import '~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references';
.access-denied
{
    margin-top: 85px;   
    padding: 15px;
    background: $ms-color-white;
   .access-denied-header
   {
       text-align: center;
        .error-icon
        {
            padding-bottom:10px;           
            vertical-align: middle; 
            color:$ms-color-red;
            font-size: 50px;
            text-align: center;
        }
        .header-text
        {
            color:$ms-color-black-different-shade-04;
            font-size: 45px;
            font-weight: 400;
        }
    }
    .access-denied-body
    {    
        color:$ms-color-black;
        text-align: center;  
        font-size: 15px;
        padding: 0px;        
        margin: 0px;
        .message
        {
            font-size: 16px;
            .link-to-oem
            {
                margin: 0px 5px;
            }
            
        }
       
    }


  //small screen 320px - 479px
  @media only screen and (max-width: 479px) and (min-width: 320px)  {

    .access-denied-header
    {
        .header-text
        {
            font-size: 40px;
        }  
    }
    .access-denied-body
    {
        .message
        {
            font-size: 14px;
            
        }
    }
  }
  
} 