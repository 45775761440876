@import '~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references';
.unexpected-error
{
    margin-top: 85px;  
    padding: 15px;
    background: $ms-color-white;
   .unexpected-error-header
    {
         text-align: center;
         .error-icon
         {
             padding-bottom:10px;           
             vertical-align: middle; 
             color:$ms-color-red;
             font-size: 50px;
             text-align: center;
         }
         .header-text
         {
             color:$ms-color-black-different-shade-04;
             font-size: 45px;
             font-weight: 400;
         }
    }
    .unexpected-error-body
    {
        color:$ms-color-black;
        text-align: center;  
        font-size: 15px;
        padding: 0px;        
        margin: 0px;
        .message
        {
            font-size: 16px;
            .link-to-oem
            {
                margin: 0px 5px;
            }
            
        }
    }

    //small screen 320px - 479px
@media only screen and (max-width: 479px) and (min-width: 320px)  {
    .unexpected-error-header
    {
        .header-text
        {
            font-size: 35px;
        }      
    }
    .unexpected-error-body
    {
        .message
        {
            font-size: 14px;
            
        }
    }
  }
}