@import '../../resources/styles/variables/references';
.pin-input-section
{
  text-align: center;
  .pin-input-boxes
  {	
    background: $ms-color-gray-f-shade-01;
    display: inline-block;
    font-size: 16px;
    .icon-message
    {
        background: $ms-color-white;       
        font-size: 32px;
        .ms-Icon
        {
          padding: 10px;
          border-radius: 50%;
        }
    }
    .lock-icon-message
    { 
      color:$ms-color-black-different-shade-063;
        .ms-Icon
        {
          border: 1px solid $ms-color-black-different-shade-063;
        }
    }
    .completed-icon-message
    {
      display: none;
      color:$ms-color-green;
        .ms-Icon
        {
          border: 1px solid $ms-color-green;
        }
    }
    .errorBadge-icon-message
    {
      display: none;
      color:$ms-color-red;
      .ms-Icon
      {
        border: 1px solid $ms-color-red;
      }
    }

      .administrator-title
      {
        background: $ms-color-white;
        padding: 10px 0px;
        font-size: 18px;
        color: $ms-color-black-different-shade-05E;
      

      }
      .enter-pin-title
      {
        font-size: 16px;
        font-weight: 500;
        padding: 10px 0px;
        color: $ms-color-black-different-shade-05E;
      }
      .input-box-section
      {
        padding: 10px 0px;   
        .user-input-pin {                 
            margin:5px;
            border: 1px solid  $ms-color-gray-c-shade-01;
            font-size:25px;
            width: 40px;
            height: 25px;
            color:$ms-color-black;
            text-align:center;
          } 
      }
      
      .success-error-message
      {
        background: $ms-color-white;
        padding: 10px 0px;
        font-size: 16px;
        .success-message
        {
          display: none;
          text-align: center;
          color:$ms-color-green;
          .sign-out-button
          {
            padding: 10px 0px;

          }
        }
        .error-message
        {
          display: none;
          color:$ms-color-red;

        }
      }
  }

  .temp-button
  {
    margin: 300px 0px;
    height: 35px;    
    text-align: left;
    .test
    {
      margin: 0px 10px;
    }

  }

  //success-messages
    .input-success-messages
  {	
      .lock-icon-message
      { 
      display: none;
      }
      .completed-icon-message
      {
        display: block;      
      }
      .errorBadge-icon-message
      {
        display: none;
      }
      .success-error-message
      {     
          .success-message
          {
            display: block;         
          }
          .error-message
          {
            display: none;
          }
      }
  }
  //error message 
  .input-error-messages
  {	
    .lock-icon-message
    { 
    display: none;
    }
    .completed-icon-message
    {
      display: none;      
    }
    .errorBadge-icon-message
    {
      display: block;
    }
    .success-error-message
    {     
        .success-message
        {
          display: none;         
        }
        .error-message
        {
          display: block;
        }
    }
  }
}