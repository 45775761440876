@import '~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references';
.user-profile-panel
{  
     //panel Header Section
    margin-top: 39px;      
    padding: 0px 10px 0px 15px;
    .ms-Panel-main
    {
        max-width: 400px;
        max-height: 280px;
        background:$ms-color-black-different-shade-04F;  
        font-family: "Segoe UI";
        //panel title styling
        .ms-Panel-commands
        {
        padding:5px 20px 10px 20px; 
            .panel-title-section
            {
                font-size: 18px;
                color: $ms-color-white;
                font-weight: 400;
                border-bottom: 1px solid $ms-color-black-different-shade-07; 
                padding-left: 8px;
                padding-bottom: 5px;
                .panel-title
                {
                    text-align: justify;
                    padding: 5px 0px 5px 0px;
                }
            }
        }
    }
    
    //Panel Content Section   
    .ms-Panel-contentInner
    {
        .ms-Panel-scrollableContent
        {
            .ms-Panel-content
            {
             padding:0px; 
                .user-profile-basic-info-template
                {
                    border-bottom: 1px solid $ms-color-black-different-shade-08; 
                    padding-bottom: 10px;                   
                    .ms-Persona 
                    {  
                        padding: 10px;
                        .ms-Persona-coin {
                            .ms-Persona-initials{
                                background-color: $ms-color-white;
                                color: $ms-color-black;
                                font-size: 22px;
                                }
                        }
                        .ms-Persona-details
                        {
                            //padding-top: 30px;
                            .primary-text{
                                font-size: 18px;
                                color:  $ms-color-white;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }
                            .secondary-text{
                                font-size: 14px;
                                color:  $ms-color-white;
                            }
                        }
                    }
                }
                .user-profile-detail-info-template
                {
                    padding:10px 20px 5px 20px;
                    overflow: hidden;
                    position: sticky;
                    //panel header 
                    .header-section-text
                    {
                        font-size: 16px;
                        color:$ms-color-white;
                        font-weight: 500; 
                        padding-bottom: 5px;
                    
                        .header-text{
                        text-align: justify;
                        padding-bottom: 5px;
                        }

                        .right-side-link, .ms-Link{
                            color: $ms-color-white;  
                            font-size: 15px;
                            text-align: right;
                            font-weight: 500;
                            padding-top: 5px;
                            cursor:pointer;

                            a{
                            border:0px;
                            cursor:pointer;
                            color: $ms-color-white;  
                            text-decoration: none;              
                            }

                            >a:hover,>a:focus {
                            outline: 1px solid  $ms-color-white;                  
                            }  
                        }

                        .right-side-link:hover,.right-side-link:focus{
                        outline: 1px solid  $ms-color-white;  
                        }
                    } 
                    // Notification panel
                    .panel-section
                    {
                        font-family: "Segoe UI";        
                        color: $ms-color-white;
                        font-weight: 100;
                        padding:0px 0px 10px 0px;
                        border-bottom: 1px solid $ms-color-black-different-shade-07; 
                        
                        .panel-section-title {
                            padding-bottom: 10px;
                            font-weight: 500;            	
                            font-size: 14px;
                        }
                        
                        .panel-section-text 
                        {
                        font-size: 14px;
                        color: $ms-color-white;
                        font-weight: 100;

                        .notification-text {
                            text-align: justify;                 
                        }
                        .notification-date {
                            text-align: right;
                            }
                        .contents-section-text
                        {
                            text-align: justify;
                            margin-top: 10px;       
                        
                            .contents-section-text-link{
                                cursor:pointer;
                                color: $ms-color-white; 
                                text-decoration: none;
                                    .ms-Icon{
                                        vertical-align: middle;
                                    }
                                }  
                            .contents-section-text-link:hover,
                            .contents-section-text-link:focus {
                                outline: 1px solid $ms-color-white;                
                                } 
                            
                                .language-menu
                                {
                                    .language-menus-button
                                    {
                                        background: transparent;                                      
                                        margin:0px 0px 0px -12px;
                                        .ms-Button-flexContainer
                                        {
                                            flex-direction:row-reverse;
                                            .ms-Button-label
                                            {
                                                font-size: 14px;
                                                color: $ms-color-white;
                                                font-weight: 100;
                                            }                                            
                                            .ms-Button-menuIcon{
                                                font-size: 14px;
                                                color: $ms-color-white;
                                                font-weight: 100;
                                                transform: rotate(-270deg);
                                                margin: 0px 1px 0px -12px;
                                            }
                                            @media only screen and (max-width: 528px) and (min-width: 320px)  {
                                                flex-direction:unset;
                                                .ms-Button-menuIcon{
                                                    transform: rotate(270deg);
                                                    margin: 2px 0px 0px 2px;
                                                }
                                            }
                                            
                                        }                                      

                                    }
                                    .language-menus-button:hover,.language-menus-button:focus
                                    {
                                        outline: 1px solid $ms-color-white;  
                                        .ms-Button-label:hover,.ms-Button-label:focus
                                        {
                                            outline: 1px solid $ms-color-white !important; 
                                        }

                                    }
                                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                                        .language-menus-button:hover,.language-menus-button:focus
                                        {
                                            outline: 1px solid $ms-color-white;  
                                            .ms-Button-label:hover,.ms-Button-label:focus
                                            {
                                                outline: 1px solid $ms-color-white !important; 
                                            }
    
                                        }
                                  
                                    }   
                                    padding:0px; 
                                    .ms-CommandBar
                                    {
                                        padding:0px; 
                                        height:25px;
                                        background:$ms-color-black-different-shade-04F;
                                        .ms-OverflowSet
                                        {
                                            padding:0px; 
                                            .ms-OverflowSet-item
                                            {
                                                
                                                .ms-Button
                                                {
                                                    background:$ms-color-black-different-shade-04F;
                                                    
                                                }
                                            }
                                        }
                                    }                                
                                }
                            } 
                        }                   
                    }
                    .panel-section:last-of-type
                    {
                    border: 0px;
                    }
                }
            }
        }
        //Panel Footer Section links
        .ms-Panel-footer
        {
            .ms-Panel-footerInner 
            {
                padding:0px;
                .panel-footer
                {
                position: absolute;
                padding: 10px 10px 10px 0px;
                bottom: 0;
                width: 100%;
                background-color: $ms-color-black-different-shade-06;
                    .container
                    {
                        width: auto;   
                        .ms-CommandBar
                        {
                            padding: 0px;  
                            .ms-CommandBar-primaryCommand
                            {
                             background-color: $ms-color-black-different-shade-06;
                             .ms-CommandBarItem-link
                             {
                                background-color: $ms-color-black-different-shade-06;
                                :hover
                                {
                                    background-color: $ms-color-black-different-shade-08;
                                } 
                            }

                             .ms-Button-icon
                                {
                                    color: $ms-color-white;
                                }                              
                                .panel-footer-navigation-bar
                                { 
                                    color: $ms-color-white;                
                                }
                            }
                        }  
                    }        
                }
            }   
        } 
    }
}

.sub-menu-option
{
    background: $ms-color-black-different-shade-04F;
    
        .ms-ContextualMenu-link
        {
            padding: 0px;
            .ms-ContextualMenu-linkContent 
            {   .ms-ContextualMenu-itemText
                {
                    padding: 0px 20px;
                }
            }
        }
    
    &.isSelected
    {
        .ms-ContextualMenu-itemText
        {
            padding: 0px !important;
        }
        .ms-ContextualMenu-itemText::before
        {
            content: "\2713";
            padding: 0px 5px;               
            color: $ms-color-white;
        }
    } 
    :hover
    {
        background-color: $ms-color-black-different-shade-08;
    } 
}