@import "~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references";
.selected-item-Number {     
    margin: 5px 0px;
    .remove-all-button{
        margin: 0px;           
        text-decoration: none;
        font-size: 14px;
        padding:5px; 
        vertical-align: super;
        font-weight: 450;
        cursor: pointer;
    }
    .filter-badge-item{
        background: $ms-color-gray-f-shade-04;          
        margin: 3px;  
        display: inline-block;  
        border: 1px solid $ms-color-black-different-shade-A6;
        .filter-badge-item-text {                
            max-width: 150px;
            padding:5px;
            font-size: 14px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display:inline-block;
        }
        .filter-badge-item-btn {              
            padding: 0px;
            margin: 0px;
            display: inline-block;
            vertical-align: top;
        }            
    }
}