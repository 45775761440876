@import "../../resources/styles/variables/references";
.pager{   
    display: flex;

    .page-size-selection{
        vertical-align:middle;
        padding-top: 1px;

        select{
            height: 25px;
        }
        select:focus{
            border: 1px solid #005A9E;
        }
    }

    .pagination-label{
        padding-left: 10px;
        padding-right: 10px;
        vertical-align:middle;
        padding-top: 4px;
    }

    .pagination{   
        display: block;
        margin-left: auto;

        .pageno-input {
            vertical-align:middle;
            padding-left: 10px;
            input{
                width: 40px;
                height: 20px;
                font-size: 13px;
            }
        }

       
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
        }

        ul a {
            height: 24px;
            width: 24px;
            border: 0px solid $ms-color-gray-c-shade-01;        
            display: flex;        
            justify-content: center;
            align-items: center;
            text-align: center;
            text-decoration: none;
            font-size: 14px;
            color: $ms-color-black;
            position: relative;
            -webkit-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
            color:$ms-color-blue-different-shade-02;
            padding: 0px 5px;
            margin: 0px 1px;
        }     

        a::before a::after{
            -webkit-transition: 0.3s;
            -o-transition: 0.3s;
            transition: 0.3s;
            content: "";
            position: absolute;
            height: 50%;
            background-color: $ms-color-black-different-shade-A1;
        }
        a:hover {
            background-color:transparent;
            color:$ms-color-blue-different-shade-02;           
        }
        a:focus {
            background-color:transparent;
            color:$ms-color-blue-different-shade-02; 
            border: 1px solid $ms-color-black-different-shade-A6;          
        }
        
        a.active {
            background-color:transparent;
            color:$ms-color-blue-different-shade-02;
            border: 1px solid $ms-color-black-different-shade-A6;
        } 
        .pages
        {
            .page-numbers{
                display: flex;
            }
            .page-item 
            {
                .page-link
                {
                    cursor: pointer;
                }
            }
          .page-item>a:focus , 
          .page-item>a.active:focus{
          border:1px solid $ms-color-black;
          }
  
          .page-item.disabled:focus
          {
            outline:0px solid;
          }
        } 
    }
    //medium screen 480px - 639px
    @media only screen and (max-width:639px) and (min-width: 480px)  {        
         text-align: center;  
        .pagination
        {
            .pages
            {
                justify-content: center;
            }
        }
    }
    //small screen 320px - 479px
    @media only screen and (max-width: 479px) and (min-width: 320px)  {
        text-align: center;      
        .pagination
        {
            .pages
            {
                justify-content: center;
            }
        }        
    }  
}

