@import '../../resources/styles/variables/references';
.alertList{
    margin: 15px 0;
    .ms-MessageBar--success
    {
      background:$ms-color-light-green-different-shade-D9;
      color: $ms-color-black-different-shade-021;
    }
    .messageBarType-info
    {
      background:$ms-color-light-blue-different-shade-01;
      color: $ms-color-blue-different-shade-00;
      .ms-MessageBar-icon
      {
        i{
          color: $ms-color-blue-different-shade-00;
        }
      }
    }
}
.sticky-alert
{
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1000;
}