@import '../../resources/styles/variables/references';
.file-upload
{
    .section-label{
        .mandatory-field-symbol{
            color: rgb(168, 0, 0);
            padding-right: 12px;   
        }
    }
    .file-upload-error{
        color: rgb(168, 0, 0);
    }
   
    .browse-button-section
    {
        padding: 0px 20px 30px;
        background:$ms-color-gray-f-shade-01;
        margin: 0px 0px 20px;
        .section-label
        {
            font-size: 14px;
            padding: 10px 0px;
            color: $ms-color-black;
            .mandatory-field-symbol
            {
                color:$ms-color-red-different-shade-01;
                font-size: 14px;
            }
        }
      
        .browse-button
        {
            display: flex;
            @media only screen and (max-width: 799px) and (min-width: 320px) {
                display: block;                
            }
            
            input[type=file] {
                display: none;
            }
            .custom-file-upload {
                background: $ms-color-white;
                color:$ms-color-blue-different-shade-02;
                display: inline-block;
                border: 1px solid  $ms-color-gray-c-shade-01;                       
                padding: 8px 20px;
                cursor: pointer;   
                min-width: 80px;
                text-align: center;
                margin-top: 29px;
                .ms-Icon
                {
                    vertical-align: middle;
                }     
                height: 21px;               
            }
            .user-selected-path-box
            {   .ms-TextField-fieldGroup 
                {                   
                    cursor: pointer;                              
                    height: 39px;
                    .ms-TextField-field
                    {
                    padding: 5px;
                    }
                }
            }
        }
    }
}