@import '../../../resources/styles/variables/references';
.confirmation-dialog-box
{	
	.ms-Dialog-main
	{
		.ms-Dialog-header
		{
            .ms-Dialog-title
            {
                font-size: 22px;
                color: $ms-color-black;
                font-weight: 400;
                text-align: left;
            }
		}
		.ms-Dialog-inner
		{
			.ms-Dialog-content
			{
				.ms-Dialog-subText
                {
                    font-size: 14px;
                    font-weight: 400;
                    color: $ms-color-black;
                    text-align: justify;
                    padding-bottom: 0px;

                }
                .ms-TextField
                {
                    margin: 0px 0px 20px;
                }
			}
			.ms-Dialog-actions
			{
                margin: 10px 0px;
                .ms-Dialog-actionsRight
                {
                    .ms-Dialog-action
                    {
                       .ms-Button--default 
                       {
                        background: $ms-color-white;
                        border: 1px solid $ms-color-gray-c-shade-01;
                        border-radius: 4px;
                       }
                    }
                }
			}
		}
	}
}