@import '~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references';
.link
{
 text-align: justify;
 margin-bottom: 10px; 
 
 .text-link{
 cursor:pointer;
 color: $ms-color-white; 
 text-decoration: underline;
 word-spacing: 3px;
 } 
 .text-link:hover,
 .text-link:focus {
 color:$ms-color-white;
 outline: 1px solid $ms-color-white; 
 } 
 
}