@import "../../resources/styles/variables/references";

.tag-picker-box {
  .tag-picker-tooltip
  {   
      padding: 10px 0px;
    min-width: 80px;  
    .date-range-note 
    {
      text-align: justify;
    } 
  }
  .infotooltipicon {
		color: $ms-color-blue-different-shade-01;
		font-weight: 500;
		font-size: 16px;
		padding: 0px 0px 0px 5px;
		cursor: pointer;
	}
  .mandatory-field {
    color: $ms-color-red-different-shade-01;
    padding: 0px 0px;
    font-size: 12px;
  }
  .ms-Label {
    padding: 8px 0px 5px 0px;
    font-weight: 600;
    .mandatory-field {
      color: $ms-color-red-different-shade-01;
      padding: 0px 5px;
    }
    .infotooltipicon
			{
				color: $ms-color-blue-different-shade-01;
				font-weight: 500;
				font-size: 16px;
				padding: 0px 0px 0px 5px;
			}
  }
  .picker-list {
    .ms-BasePicker {
      .ms-BasePicker-text {
        .ms-BasePicker-itemsWrapper {
          padding: 1px 0px;
        }
        .tag-picker-box-error {
          outline: 1.5px solid $ms-color-red-different-shade-01;
          outline-offset: 0px;
        }
      }
    }
  }
}

.ms-Suggestions-container {
  .ms-Suggestions-item {
    .ms-Suggestions-itemButton {
      .ms-Button-flexContainer {
        .ms-TagItem-TextOverflow {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}

@media (forced-colors: none) {
  /* width */
  .ms-Suggestions-container::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  .ms-Suggestions-container::-webkit-scrollbar-track {
    background: $ms-color-gray-c-shade-01;
  }

  /* Handle */
  .ms-Suggestions-container::-webkit-scrollbar-thumb {
    background: $ms-color-black-different-shade-08;
  }

  /* Handle on hover */
  .ms-Suggestions-container::-webkit-scrollbar-thumb:hover {
    background: $ms-color-black-different-shade-08;
  }
}

/*#sug-selectedAction {
	background-color: #edebe9 !important;
	border: 1px solid rgb(96, 94, 92) !important;
}*/