@import '../../resources/styles/variables/references';

.card {
    border: 1px solid $ms-color-card-border;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);  
    background-color: $ms-color-card-background;
    .card-title{
        color:$ms-color-themePrimaryDark;
    } 
}
.card-body {
    background-color: $ms-color-white;
    padding: 10px;
}

.card-header {
    padding: 10px;
}

.horizontal-card
{
    .accordion 
    {
            border-top: 0px solid $ms-color-gray-d-shade-07;
            margin: 10px 0px;
            cursor: pointer;
            .accordion-header {
            padding: 0px  10px 0px 0px;
            background-color: $ms-color-gray-f-shade-01;
            border: 0px solid $ms-color-gray-d-shade-07;
            color: $ms-color-black;
            width: auto;
            cursor: pointer;
            .accordion-header-text {
                width: 100%;
                background-color: $ms-color-gray-f-shade-01;
                padding-left: 0px;
                cursor: default;
                .card-label {
                font-weight: 500;          
                padding-bottom: 0px;
                }
                .card-value
                {
                font-size: 15px; 
                padding-top: 2px;                
                }
            }
            .accordion-header-icon { 
                padding: 1px;       
                //background: 	$ms-color-gray-d-shade-07;
                .ms-Icon
                {
                color: $ms-color-black;
                padding: 10px;  
                } 
                :hover
                {
                background: 	$ms-color-gray-d-shade-07;
                }
            }
            .accordion-header-icon:focus
            {
            outline: 1px solid $ms-color-black-different-shade-07; 
            } 
            }
            &.expanded 
            {
            .accordion-header {
                border: 1px solid $ms-color-gray-d-shade-07;
            }
            }
        
            .accordion-body {
            cursor: default;
            border: 1px solid $ms-color-gray-d-shade-07;
            border-top: 0px solid $ms-color-gray-d-shade-07;
            width: auto;
                .card-label
                {
                    padding-bottom:0px; 
                    font-weight: 500;              
                }
                .card-value
                {
                font-size: 15px; 
                padding-top: 2px;                
                }
            }
      }
}