@import '~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references';
$font-size-base: 1rem;
$button-line-height: 2.75;
$line-height-minus: 0.1875rem;
$h1-font-size: $font-size-base * 1.5;
$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1342px);
$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1230px, xxl:1590px );

// Mobile font-face sizes
$h1-font-sm-size: 1.5rem;
$h2-font-sm-size: 1.125rem;
$h3-font-sm-size: 1.125rem;
$h4-font-sm-size: 1.125rem;
$h5-font-sm-size: $font-size-base;
$h6-font-sm-size: $font-size-base;
$button-font-sm-size: 0.9375rem;
$caption-font-sm-size: 0.875rem;
$footer-font-sm-size: 0.75rem; //12px
$adjustment-sm-size: 0.6875rem; //11px
$small-font-sm-size: 0.625rem; //10px

// Desktop font-face sizes
$h1-font-lg-size: 2.125rem;
$h2-font-lg-size: 1.75rem;
$h3-font-lg-size: 1.5rem;
$h4-font-lg-size: 1.25rem;
$h5-font-lg-size: 1.125rem;
$h6-font-lg-size: $font-size-base;
$button-font-lg-size: 0.9375rem;
$caption-font-lg-size: 0.875rem;
$footer-font-lg-size: 0.75rem;
$small-font-lg-size: 0.625rem;

#header 
{
    background: $ms-color-black-different-shade-021;

    #globalHeader {
        .global-header-wrapper 
        {
            position: relative;
            display: flex;
            flex-wrap: wrap;    
            justify-content: space-between;
            height: 40px;
            background: $ms-color-black-different-shade-021;
            border: 0px;
            .seperator-mobileview,.oem-doc-mobileview
            {
                display: none;
            }
            .user-dropdown
            {
                .ms-Button-icon
                {
                    color: $ms-color-white;
                }
            }

            .global-header-menus 
            {
                float: right;
                .commandBar-menus
                {
                    display: flex;
                    .microsoft-agreement-number
                    {
                        font-size: 15px;
                        margin-top: 10px;
                        color:$ms-color-white;
                        max-width: 285px;
                    }
                    .ms-CommandBar
                    {
                        background: $ms-color-black-different-shade-021;
                        padding-left: 5px;
                        .ms-OverflowSet
                        {
                            .ms-OverflowSet-item
                            {
                                .ms-CommandBarItem-link 
                                {
                                    background: $ms-color-black-different-shade-021;
                                    .ms-Button-flexContainer 
                                    {                    
                                        .ms-Button-icon
                                        {
                                            color:$ms-color-white;
                                        }
                                        .ms-Button-menuIcon
                                        {
                                            color:$ms-color-white;
                                        }
                                        .ms-Persona-initials
                                        {
                                            background-color: $ms-color-black-different-shade-021;
                                            border: 1px solid $ms-color-white;
                                        }
                                    }
                                }                                
                                .ms-CommandBarItem-link:hover, .ms-CommandBarItem-link:focus, .active-icon
                                {
                                    background: $ms-color-black-different-shade-07;
                                }
                                .hide-icon
                                {
                                    display: none;
                                }                
                                .notifications-icon
                                {
                                    height: 24px;
                                    width: 24px;                                  
                                    background: url('../../../resources/images/notification-icon.png')  no-repeat;
                                    background-size: contain;
                                    @media screen and (-ms-high-contrast: black-on-white) {
                                        background: url('../../../resources/images/notification-icon-black.png')  no-repeat !important;
                                        background-size: contain !important;
                                    }                                    
                                } 
                                .notificationoption  
                                {
                                    .ringer-icon
                                    {
                                        font-size: 18px;
                                    }
                                }
                                .visibility-hidden
                                {
                                    visibility: hidden;
                                }
                                .visibility-visible
                                {
                                    visibility: visible;
                                }             
                            }              
                        }
                    }
                }
            }
        }
    }
}

//Agreement number handle in Mobile View
//large screen	640px - till spefic view port 850px
@media only screen and (max-width: 850px) and (min-width:640px)  {
    .global-header-wrapper {  
        .seperator,.oem-doc
        {
            display: none;
        }
        .seperator-mobileview,.oem-doc-mobileview
        {
            display: inline-block !important;
            width: 10px;
            color: $ms-color-white;
            padding: 10px 0px 0px 2px;
            text-decoration: none;
            @media screen and (-ms-high-contrast: black-on-white) {
                color: $ms-color-black !important;                
            }             
        }
        .global-header-menus {
            .commandBar-menus {
                .microsoft-agreement-number {
                    display: none;
                }
            }
        }  
    }
}
//medium screen 480px - 639px
@media only screen and (max-width:639px) and (min-width: 480px)  {
    .global-header-wrapper {  
        .seperator,.oem-doc
        {
            display: none;
        }
        .seperator-mobileview,.oem-doc-mobileview
        {
            display: inline-block !important;
            width: 10px;
            color: $ms-color-white;
            padding: 10px 0px 0px 2px;
            text-decoration: none;
            @media screen and (-ms-high-contrast: black-on-white) {
                color: $ms-color-black !important;                
            } 
        }
        .global-header-menus {
            .commandBar-menus {
                .microsoft-agreement-number {
                    display: none;
                }
            }
        }  
    }
}
  //small screen 320px - 479px
  @media only screen and (max-width: 479px) and (min-width: 320px)  {      
    .global-header-wrapper {
        .seperator,.oem-doc
        {
            display: none;
        }
        .seperator-mobileview,.oem-doc-mobileview
        {
            display: inline-block !important;
            width: 10px;
            color: $ms-color-white;
            padding: 10px 0px 0px 2px;
            text-decoration: none;
            @media screen and (-ms-high-contrast: black-on-white) {
                color: $ms-color-black !important;                
            } 
        }
        .global-header-menus {
            .commandBar-menus {
                .microsoft-agreement-number {
                    display: none;
                }
            }
        }
        .navbar-brand {
            &.microsoft {
                height: 34px;
                width: 34px;
                overflow: hidden;
                padding: 0;
                margin: 0px 0px 0px -5px;
                float: left;

                img {
                    height: 24px;
                    width: auto;
                    float: left;                   
                }
            }            
        }        
    }
}


.agreement-number-tooltip
{
    border: 1px solid $ms-color-black-different-shade-03;
    padding: 5px 20px;
    .ms-Callout-beak
    {
        background: $ms-color-black-different-shade-03;
    }
    .ms-Callout-beakCurtain 
    {
    background: $ms-color-black-different-shade-03;
    }
    .ms-Callout-main 
    {
        background: $ms-color-black-different-shade-03;
        .ms-Tooltip-content 
        {
            background: $ms-color-black-different-shade-03;
            
            .ms-Tooltip-subtext
            {
                color: $ms-color-white;
            }
        }

    }

}



.doc-title-tooltip
{
    border: 1px solid $ms-color-black-different-shade-03;
    padding: 5px 20px;
    .ms-Callout-beak
    {
        background: $ms-color-black-different-shade-03;
    }
    .ms-Callout-beakCurtain 
    {
    background: $ms-color-black-different-shade-03;
    }
    .ms-Callout-main 
    {
        background: $ms-color-black-different-shade-03;
        .ms-Tooltip-content 
        {
            background: $ms-color-black-different-shade-03;
            
            .ms-Tooltip-subtext
            {
                color: $ms-color-white;
            }
        }

    }

}

.tooltip-main-section-content
{
    a{
        text-decoration: underline;
    }
    
    &:focus
    {
        outline: 0px solid !important;
        border: 1px solid !important;
    }
    .sub-content-tooltip
    {
        &:focus
        {
            outline: 0px solid !important;
            border: 0px solid !important;
        }

    }
}

