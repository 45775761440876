@import "../../resources/styles/variables/references";
.column-picker-list
{
    padding: 10px;
    .select-all-option
    {
        border-bottom:1px solid $ms-color-gray-d-shade-07;
        margin: 5px 0px;
        padding: 10px;
    }
    .column-name-text 
    {
        padding: 10px;
    }
}