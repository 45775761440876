@import "../../resources/styles/variables/references";
.pivot-control-section
{
    margin: 20px 0px;   
    .ms-Pivot
    {
        border-bottom: 1px solid $ms-color-gray-c-shade-01;
        .ms-Pivot-link
        {
            padding: 10px;
            min-width: 180px;
            background: transparent;
            border: 1px solid $ms-color-gray-c-shade-01;
            border-bottom: 0px solid $ms-color-gray-c-shade-01;
            font-size: 16px;
            .ms-Button-flexContainer
            {
            justify-content: center;
            }
            &.is-selected
            {
                background: $ms-color-blue-different-shade-02;
                font-weight: 500;
                font-size: 15px;
                border: 0px solid $ms-color-gray-c-shade-01;

                @media screen and (-ms-high-contrast: black-on-white) {
                    color: $ms-color-white !important;
                    background: $ms-color-black;
                }
                @media screen and (-ms-high-contrast: white-on-black) {
                    color: $ms-color-black !important;
                    background: $ms-color-white;
                }
            }
            &.is-selected::before
            {
                background: transparent;

                @media screen and (-ms-high-contrast: active) {
                    color: $ms-color-white !important;
                }
            }
            @media only screen and (max-width: 1023px) and (min-width:640px)  {
                min-width: 140px;
              
            }
            //medium screen 480px - 639px
            @media only screen and (max-width:639px) and (min-width: 480px)  {
                min-width: 100px;                
            }
                //small screen 320px - 479px
            @media only screen and (max-width: 479px) and (min-width: 320px)  {
                min-width: 130px;
                max-width: 130px;
                display: block;
            }
        }
        .ms-Pivot-link:focus,
        .ms-Pivot-link:hover
        {
            .ms-Pivot-text
            {
                color:$ms-color-black;                
            }

            @media screen and (-ms-high-contrast: black-on-white) {
                outline: 1px solid $ms-color-black !important;
                border: 2px solid $ms-color-white !important;
            }
            @media screen and (-ms-high-contrast: white-on-black) {
                outline: 1px solid $ms-color-blue-different-shade-04 !important;
                border: 2px solid $ms-color-white !important;
            }

            &.is-selected
            {
             outline: 1px solid $ms-color-black !important;
             border: 2px solid $ms-color-white !important;
                .ms-Pivot-text
                {
                    color:$ms-color-white;
                }

                @media screen and (-ms-high-contrast: black-on-white) {
                    outline: 1px solid $ms-color-black !important;
                    border: 2px solid $ms-color-white !important;
                }
                @media screen and (-ms-high-contrast: white-on-black) {
                    outline: 1px solid $ms-color-white!important;
                    border: 2px solid $ms-color-black !important;

                    .ms-Pivot-text
                    {
                        color:$ms-color-black;
                    }
                }
            }
        }
    }
    .pivot-item-section
    {
        min-height: 120px;      
    }
    
}