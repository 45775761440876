@import '../../resources/styles/variables/references';

.autocomplete {
    text-align: left;
    .autocomplete-details {
        height: 125px;
        max-height: 125px;
        right: 15px;
        left: 15px;
        border-top: 0px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        position: absolute;
        z-index: 1;
        }
        .ms-DetailsRow-cell
        {
           
            font-size: 16px;
            color: $ms-color-black;
            background-color: $ms-color-card-background;
            &:hover, &:focus{
                background: $ms-color-active-button ;
                color: $ms-color-white;
                font-weight: bold;
            }
        }
    .loader{
        position: relative;
    }
    .loader-position{
        position: absolute;
        right: 2px;
        padding: 34px 4px 0 0;
        z-index: 1;
    }
}



.auto-complete {   
    position: relative;
    display: inline-block;
    width: 100%;
    
    .auto-complete-list-items {
        background: $ms-color-white;
        position: absolute;
        border: 1px solid $ms-color-gray-d-shade-04;
        border-bottom: none;
        border-top: none;
        z-index: 1000000;
        top: 100%;
        left: 0;
        right: 0;
        overflow-y: auto;
        max-height: 190px;
        padding:0px;
        margin: 0px;
        .auto-complete-list-item
        {
            cursor: pointer;
            padding: 10px 5px;
            border-bottom: 1px solid $ms-color-gray-d-shade-04;
            background: $ms-color-white;
            &:hover {
                background: $ms-color-gray-f-shade-01;                
            }  
            &.auto-complete-active ,&:focus            
            {
                background: $DodgerBlue; 
                color:$ms-color-white;
            } 
        }
    }
}

.auto-result-populated-list
{
        .statusmessage
		{
		position:absolute;
		width:1px;
		height:1px;
		margin-top:-1px;
		margin-right:-1px;
		margin-bottom:-1px;
		margin-left:-1px;
		padding-top:0px;
		padding-right:0px;
		padding-bottom:0px;
		padding-left:0px;
		border:0px;
		overflow:hidden;
        }
}