@import '~@ec-oem/ec.oem.oa3.ui.core/resources/styles/variables/references';
.business-model-panel {
  //panel Header Section
  margin-top: 39px;
  padding: 0px 10px 0px 15px;
  .ms-Panel-main {
    max-width: 400px;
    background: $ms-color-black-different-shade-04F;
    font-family: "Segoe UI";
    //panel title styling
    .ms-Panel-commands {
      padding: 5px 20px 10px 20px;
      .panel-title-section {
        font-size: 18px;
        color: $ms-color-white;
        font-weight: 400;
        border-bottom: 1px solid $ms-color-black-different-shade-07;
        padding-left: 8px;
        padding-bottom: 5px;
        .panel-title {
          text-align: justify;
          padding: 5px 0px 5px 0px;
        }
      }
    }
  }

  //Panel Content Section
  .ms-Panel-contentInner {
    .ms-Panel-scrollableContent {
      .ms-Panel-content {
        padding: 0px;
        .business-model-template {
          padding: 1px 20px 10px 20px;
          //panel header
          .header-section-text {
            font-size: 16px;
            color: $ms-color-white;
            font-weight: 400;
            padding-bottom: 5px;
            cursor: pointer;

            .header-text {
              text-align: justify;
              height: 30px;
              padding-left: 8px;
              .ms-Icon {
                vertical-align: middle;
                visibility: hidden;
                &.selected{
                  visibility: visible;
                  padding-top: 2px;
                }
              }
              .microsoft-agreement-number {
                vertical-align: middle;
                padding-left: 8px;
              }
            }
            .header-text:hover,
            .header-text:focus {
              outline: 1px solid $ms-color-white;
              background: $ms-color-black-different-shade-08;
            }
          }

          // panel contents
          .panel-section {
            font-family: "Segoe UI";
            color: $ms-color-white;
            font-weight: 100;
            padding: 10px 0px 10px 0px;
            border-bottom: 1px solid $ms-color-black-different-shade-07;
            .panel-section-text {
              font-size: 14px;
              color: $ms-color-white;
              font-weight: 100;

              .contents-section-text {
                text-align: justify;
                margin-bottom: 10px;

                .contents-section-text-link {
                  cursor: pointer;
                  color: $ms-color-white;
                  text-decoration: underline;
                  word-spacing: 3px;
                }
                .contents-section-text-link:hover,
                .contents-section-text-link:focus {
                  outline: 1px solid $ms-color-white;
                }
              }
            }
          }
          .panel-section:last-of-type {
            border: 0px;
          }
        }
      }
    }
    //Panel Footer Section links
    .ms-Panel-footer {
      .ms-Panel-footerInner {
        padding: 0px;
        .panel-footer {
          position: absolute;
          padding: 10px;
          bottom: 0;
          width: 100%;
          background-color: $ms-color-black-different-shade-06;
          .container {
            width: auto;
            .panel-footer-navigation-bar {
              color: $ms-color-white;
            }
          }
        }
      }
    }
  }
}
